exports.onInitialClientRender = () => {

  require('@fontsource/poppins')

  // On scroll event & function
  window.onscroll = function() { scrollFunction() }
  function scrollFunction() {
    if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
      document.getElementsByTagName("header")[0].classList.add("sticky")
    } else {
      document.getElementsByTagName("header")[0].classList.remove("sticky")
    }
  }

}

exports.onClientEntry = () => {

  window.onload = () => {

    // NavMobile toggle
    var navMobile = document.getElementById("navMobile")
    if (navMobile != null) {
      navMobile.addEventListener("click", function() {
        let nav = document.getElementsByTagName("nav")[0];
        if (!nav.style.display || nav.style.display === 'none') {
          nav.style.display = 'block';
        } else {
          nav.style.display = 'none';
        }
      });
    }

  }

}