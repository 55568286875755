exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akeneo-js": () => import("./../../../src/pages/akeneo.js" /* webpackChunkName: "component---src-pages-akeneo-js" */),
  "component---src-pages-corevist-js": () => import("./../../../src/pages/corevist.js" /* webpackChunkName: "component---src-pages-corevist-js" */),
  "component---src-pages-experiencia-js": () => import("./../../../src/pages/experiencia.js" /* webpackChunkName: "component---src-pages-experiencia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lengow-js": () => import("./../../../src/pages/lengow.js" /* webpackChunkName: "component---src-pages-lengow-js" */),
  "component---src-pages-magento-js": () => import("./../../../src/pages/magento.js" /* webpackChunkName: "component---src-pages-magento-js" */),
  "component---src-pages-minderest-js": () => import("./../../../src/pages/minderest.js" /* webpackChunkName: "component---src-pages-minderest-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-relevanc-js": () => import("./../../../src/pages/relevanc.js" /* webpackChunkName: "component---src-pages-relevanc-js" */),
  "component---src-pages-shopify-js": () => import("./../../../src/pages/shopify.js" /* webpackChunkName: "component---src-pages-shopify-js" */)
}

